import styled from "styled-components";
import React from "react";
import Logo from "../images/Logo.png"
import Background from "../images/Frame355.png"
import Elipse from "../images/Ellipse 19.png"
import { getTranslation } from "data/dictionary";

const BuildUp = styled.img``


const Main = styled.div`
   width: 100%;
  height: 100vh;
    overflow: hidden;
    font-family: "Lexend";
    background-image: url(${Background});
     background-position: right;
  background-repeat: no-repeat;
//   background-size: cover;

    .cont{
        width: 100%;
        height: 100vh;
    //     background-image: url(${Elipse});
    // background-position: center;
    // background-repeat: no-repeat;
    // overflow: hidden;

    }

    .text{
        display: flex;
        flex-direction: column;
        margin-left: 0 !important;
        transform: translate(250px, 120px);
        line-height: 1.2;

        .little{
            font-weight: 400;
            font-size: 34px;
        }

        .big{
            font-weight: 700;
            font-size: 66px;
        }
    }

    .elipse{
            width: 900px;
transform: translate(417px, 200px);
    }

    .background{
       position: absolute;
    width: 950px;
    margin-left: 25px;
    transform: translate(-390px, -10px);
    }

    .logo{
               transform: translate(160px, -130px);
    }

`

const ExtensionPage = () => {
    return(
        <Main>
        {/* <img src={Elipse} className="elipse"></img> */}
        {/* <img src={Background} className="background"></img> */}
        {/* <BuildUp src={Logo} className="logo"></BuildUp> */}
        <div className="text">
            <span className="little">{getTranslation("taking-you-to", "", true)}</span>
            <span className="big">{getTranslation("chrome-store", "", true)}</span>
            
        </div>
        </Main>
    )
}

export default ExtensionPage;